
import Vue from "vue";
import moment from "moment";
import i18n from "@/plugins/i18n";
import { mixin as clickaway } from "vue-clickaway";

export default Vue.extend({
  name: "range-date-picker",
  mixins: [clickaway],
  data() {
    const dateFrom = moment();
    dateFrom.subtract(1, "year");
    const dateTo = moment();
    const minDate = moment(process.env.VUE_APP_MIN_DATE);
    return {
      dateFrom: moment(dateFrom).format("YYYY-MM-DD"),
      dateFromFormated: moment(dateFrom).format("DD.MM.YYYY"),
      dateTo: moment(dateTo).format("YYYY-MM-DD"),
      dateToFormated: moment(dateTo).format("DD.MM.YYYY"),
      menu: false,
      rangeMenu: false,
      minDate
    };
  },
  watch: {
    dateFrom(val) {
      this.dateFromFormated = this.formatDate(this.dateFrom);
      this.rangeChanged();
    },
    dateTo(val) {
      this.dateToFormated = this.formatDate(this.dateTo);
      this.rangeChanged();
    }
  },
  methods: {
    close(event: any) {
      if (event.target.id !== "fromDate" && event.target.id !== "toDate") {
        this.menu = false;
      }
    },
    formatDate(date: string): string {
      return moment(date).format("DD.MM.YYYY");
    },
    parseDate(date: string, fallback: string): string | undefined {
      const newDate = moment(date, "DD.MM.YYYY").format("YYYY-MM-DD");
      if (date.length < 10 || !newDate) {
        return fallback;
      }
      return newDate;
    },
    rangeChanged() {
      if (moment(this.dateTo).isBefore(this.dateFrom)) {
        this.dateFrom = moment(this.dateTo)
          .subtract(1, "month")
          .format("YYYY-MM-DD");
      }
      const from = moment(this.dateFrom);
      from.set({ hour: 0, minute: 0, second: 0 });
      const to = moment(this.dateTo);
      to.set({ hour: 23, minute: 59, second: 59 });
      this.$emit("rangeChanged", { from: from.toDate(), to: to.toDate() });
    },
    setRange(value: number) {
      this.dateTo = moment().format("YYYY-MM-DD");
      let from = moment().startOf("month");
      if (value > 0) {
        from = moment();
        from = from.subtract(value, "month");
      } else if (value === -1) {
        this.dateFrom = this.minDate.format("YYYY-MM-DD");
        return;
      }
      this.dateFrom = from.format("YYYY-MM-DD");
    }
  },
  computed: {
    locale(): string {
      return this.$store.state.locale;
    },
    rangeItems() {
      return [
        { title: i18n.t("TABLE.TIME.ALL"), value: -1 },
        { title: i18n.t("TABLE.TIME.LAST_12"), value: 12 },
        { title: i18n.t("TABLE.TIME.LAST_6"), value: 6 },
        { title: i18n.t("TABLE.TIME.LAST_3"), value: 3 },
        { title: i18n.t("TABLE.TIME.LAST"), value: 1 },
        { title: i18n.t("TABLE.TIME.THIS"), value: 0 }
      ];
    }
  }
});
